import { createSlice } from "@reduxjs/toolkit";
import { fetchNewsEvent, fetchSingleNewsEvent } from "./newsAction";


const initialState = {
    newsList: [],
    singleEvent: {},
    loading: false,
}

const newsAndEventSlice = createSlice({
    name: "NewsAndEvent",
    initialState,
    extraReducers(builder) {
        builder
            .addCase(fetchNewsEvent.fulfilled, (state, action) => {
                state.newsList = action?.payload?.data?.success_message;
                state.loading = false;
                state.singleEvent = {};
            })
            .addCase(fetchNewsEvent.pending, (state, action) => {
                state.newsList = [];
                state.loading = true;
                state.singleEvent = {};
            })
            .addCase(fetchNewsEvent.rejected, (state, action) => {
                state.newsList = [];
                state.loading = false;
                state.singleEvent = {};
            })
            .addCase(fetchSingleNewsEvent.fulfilled, (state, action) => {
                state.singleEvent = action?.payload?.data?.success_message;
                state.loading = false;
            })
            .addCase(fetchSingleNewsEvent.pending, (state, action) => {
                state.loading = true;
                state.singleEvent = {};
            })
            .addCase(fetchSingleNewsEvent.rejected, (state, action) => {
                state.loading = false;
                state.singleEvent = {};
            })
    }
})

const newsActions = newsAndEventSlice.actions;

export { newsActions };

export default newsAndEventSlice.reducer;
