import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getBirthdayNotifications,
  getCities,
  getCountries,
  getEducations,
  getGotra,
  getStates,
  getVillages,
} from "../../axios/axios";
import Notifications from "../../helpers/Notifications";
import Request from "../../helpers/request.service";

export const countryDropDown = createAsyncThunk(
  "master/getCountries",
  async (props, thunkAPI) => {
    try {
      const response = await getCountries();

      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const stateDropDown = createAsyncThunk(
  "master/getStates",
  async (countryId, thunkAPI) => {
    try {
      const response = await getStates(countryId);

      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const stateDropDownList = createAsyncThunk(
  "master/getStatesofIDs",
  async (countryId, thunkAPI) => {
    try {
      const response = await Request.get(`/states/?country_id=${countryId}`);
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const cityDropDownList = createAsyncThunk(
  "master/getCitiesOfIDs",
  async (stateId, thunkAPI) => {
    try {
      const response = await Request.get(`/cities/?state_id=${stateId}`);
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const cityDropDown = createAsyncThunk(
  "master/getCities",
  async (stateId, thunkAPI) => {
    try {
      const response = await getCities(stateId);

      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const villageDropDown = createAsyncThunk(
  "master/getVillages",
  async (props, thunkAPI) => {
    try {
      const response = await getVillages();

      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const gotraDropDown = createAsyncThunk(
  "master/getGotra",
  async (props, thunkAPI) => {
    try {
      const response = await getGotra();

      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const educationsDropDown = createAsyncThunk(
  "master/getEducations",
  async (props, thunkAPI) => {
    try {
      const response = await getEducations();

      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const birthdayNotifications = createAsyncThunk(
  "master/getBirthdayNotifications",
  async (props, thunkAPI) => {
    try {
      const response = await getBirthdayNotifications();

      return response.data.data;
    } catch (error) {
      if (error.response.status === 404) {
        Notifications.info("No data.");
      } else {
        Notifications.error("Something went wrong.");
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);
