import { createSlice } from "@reduxjs/toolkit";

export const accountSlice = createSlice({
  name: "account",
  initialState: {
    accounts: [],
    selectedAccount: null,
    loading: false,
    error: null,
  },
  reducers: {
    accountListLoading: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.loading = true;
      state.error = null;
      // selectedAccount: null;
    },
    getAccountListSuccess: (state, action) => {
      state.loading = false;
      state.accounts = action.payload.accounts;
      state.error = null;
      state.selectedAccount = null;
    },
    getAccountListFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.accounts = [];
      state.selectedAccount = null;
    },
    setSelectedAccount: (state, action) => {
      state.selectedAccount = action.payload.account;
    },
  },
});

const accountActions = accountSlice.actions;

export { accountActions };

export default accountSlice.reducer;
