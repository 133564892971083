import { createSlice } from "@reduxjs/toolkit";
import { committeeMaster, committeeMember } from "./contactAction";

const initialState = {
  committee: "",
  member: [],
  status: "",
  error: "",
};

const committeeMasterSlice = createSlice({
  name: "committeeMaster",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(committeeMaster.fulfilled, (state, action) => {
        state.committee = action?.payload?.data;
        state.error = "";
      })
      .addCase(committeeMaster.pending, (state, action) => {
        state.status = "Loading...";
      })
      .addCase(committeeMaster.rejected, (state, action) => {
        state.committee = "";
        state.error = action?.payload?.response?.data?.status;
      });
  },
});

const committeeMemberSlice = createSlice({
  name: "committeeMember",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(committeeMember.fulfilled, (state, action) => {
        state.member = action?.payload?.data;
        state.error = "";
      })
      .addCase(committeeMember.pending, (state, action) => {
        state.status = "Loading...";
      })
      .addCase(committeeMember.rejected, (state, action) => {
        state.member = "";
        state.error = action?.payload?.response?.data?.status;
      });
  },
});

export const committeeMasterReducer = committeeMasterSlice.reducer;
export const committeeMemberReducer = committeeMemberSlice.reducer;
// export default committeeMemberSlice.reducer;
