import { createSlice } from "@reduxjs/toolkit";
import {
  checkMemberExists,
  createNewMember,
  memberListAction,
  personalProfile,
  updatedPersonalProfile,
} from "./memberAction";

const initialState = {
  member: "",
  updatedMember: "",
  createMember: "",
  memberExists: {},
  loading: false,
  status: "",
  error: "",
};

const personalProfileSlice = createSlice({
  name: "personalProfile",
  initialState,
  reducers: {
    clearCheckMember: (state) => {
      state.memberExists = {};
    },
  },
  extraReducers(builder) {
    builder
      .addCase(personalProfile.fulfilled, (state, action) => {
        state.member = action?.payload;
        state.loading = false;
        state.error = "";
      })
      .addCase(personalProfile.pending, (state, action) => {
        state.status = "Loading...";
        state.loading = true;
      })
      .addCase(personalProfile.rejected, (state, action) => {
        state.status = "Failed to fetch data";
        state.loading = false;
        state.error = action?.payload?.response?.data?.status;
      })

      .addCase(updatedPersonalProfile.fulfilled, (state, action) => {
        state.updatedMember = action?.payload;
        state.loading = false;
        state.error = "";
      })
      .addCase(updatedPersonalProfile.pending, (state, action) => {
        state.status = "Loading...";
        state.loading = true;
      })
      .addCase(updatedPersonalProfile.rejected, (state, action) => {
        state.status = "Failed to fetch data";
        state.loading = false;
        state.error = action?.payload?.response?.data?.status;
      })

      .addCase(createNewMember.fulfilled, (state, action) => {
        state.createMember = action?.payload;
        state.loading = false;
        state.error = "";
      })
      .addCase(createNewMember.pending, (state, action) => {
        state.status = "Loading...";
        state.loading = true;
      })
      .addCase(createNewMember.rejected, (state, action) => {
        state.status = "Failed to fetch data";
        state.loading = false;
        state.error = action?.payload?.response?.data?.status;
      })

      .addCase(checkMemberExists.fulfilled, (state, action) => {
        state.memberExists = action?.payload?.success_message;
        state.loading = false;
        state.error = "";
      })
      .addCase(checkMemberExists.pending, (state, action) => {
        state.status = "Loading...";
        state.loading = true;
      })
      .addCase(checkMemberExists.rejected, (state, action) => {
        state.status = "Failed to fetch data";
        state.loading = false;
        state.error = action?.payload?.response?.data?.error_message;
      });
  },
});

export const MemberListSlice = createSlice({
  name: "memberListAndSearch",
  initialState: {
    memberList: [],
    loading: false,
    error: null,
    success: null,
    memberSearch: null,
    filterValues: {},
    appliedFilters: {},
    baseQueryRedux: null,
    totalPages: 1
  },
  reducers: {
    addSearchQuery: (state, action) => {
      state.memberSearch = action?.payload;
    },
    addFilterValues: (state, action) => {
      state.filterValues = { ...state?.filterValues, ...action?.payload };
    },
    resetFilterValues: (state, action) => {
      state.filterValues = {};
    },
    addAppliedFilters: (state, action) => {
      state.appliedFilters = action?.payload;
    },
    addBaseQuery: (state, action) => {
      state.baseQueryRedux = action?.payload;
    },
    clearAllFilterData: (state, action) => {
      state.memberSearch = null;
      state.filterValues = {};
      state.baseQueryRedux = null;
      state.appliedFilters = {};
    },
    removeAFilter: (state, action) => {
      delete state.filterValues[action?.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(memberListAction.fulfilled, (state, action) => {
        if (action.payload.reset) {
          state.memberList = action?.payload?.response?.data?.success_message;
          state.loading = false;
          state.error = false;
          state.success = true;
        } else {
          state.memberList = [
            ...state?.memberList,
            ...action?.payload?.response?.data?.success_message,
          ];
          state.loading = false;
          state.error = false;
          state.success = true;
        }
        state.totalPages = action?.payload?.response?.data?.total_pages
      })
      .addCase(memberListAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(memberListAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload?.response?.data?.error_message;
        state.success = false;
      });
  },
});

export const {
  addSearchQuery,
  addAppliedFilters,
  addBaseQuery,
  addFilterValues,
  resetFilterValues,
  clearAllFilterData,
  removeAFilter,
} = MemberListSlice.actions;

export const { clearCheckMember } = personalProfileSlice.actions;

const memberListReducer = MemberListSlice.reducer;

export { memberListReducer };

export default personalProfileSlice.reducer;
