import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import aboutSlice from "./about/aboutSlice";
import accountSlice from "./account/accountSlice";
import latestAdBannerSlice from "./adBanner/adBannerSlice";
import authSlice from "./auth/authSlice";
import browserSlice from "./browser/browserSlice";
import choghadiyaSlice from "./choghadiya/choghadiyaSlice";
import communityHelpSlice from "./communityHelp/communityHelpSlice";
import {
  committeeMasterReducer,
  committeeMemberReducer,
} from "./contact/contactSlice";
import feedbackSlice from "./feedback/feedbackSlice";
import masterSlice from "./master/masterSlice";
import memberSlice, { memberListReducer } from "./member/memberSlice";
import memberAchievementSlice from "./memberAchievement/memberAchievementSlice";
import newsSlice from "./newsAndEvent/newsSlice";
import {
  committeeMasterListReducer,
  committeeMemberListReducer,
} from "./samajCommittees/samajCommitteesSlice";

import {
  templeDetailReducer,
  templesListReducer,
} from "./samajDarshan/samajDarshanSlice";

const persistConfig = {
  key: "s3_browser",
  storage,
};

const rootReducer = combineReducers({
  accounts: accountSlice,
  browser: browserSlice,
});

const persistedRootReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: {
    persistedRootReducer,
    auth: authSlice,
    about: aboutSlice,
    member: memberSlice,
    master: masterSlice,
    feedback: feedbackSlice,
    memberList: memberListReducer,
    communityHelp: communityHelpSlice,
    latestAdBanner: latestAdBannerSlice,
    committeeMaster: committeeMasterReducer,
    committeeMember: committeeMemberReducer,
    choghadiya: choghadiyaSlice,
    committeeMasterList: committeeMasterListReducer,
    committeeMemberList: committeeMemberListReducer,
    newsEventList: newsSlice,
    templesList: templesListReducer,
    templeDetail: templeDetailReducer,
    memberAchievement: memberAchievementSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
