import Request from "../helpers/request.service";

// ABOUT US
export function getAboutUsContent() {
  return Request.get("/about/");
}

// MEMBER
export function getPersonalProfile(memberId) {
  return Request.get(`/member/${memberId}/`);
}

export function updatePersonalProfile(memberId, data) {
  return Request.put(`/member/${memberId}/`, data);
}

export function createMember(data) {
  return Request.postJson("/add_member/", data);
}

export function checkMember(mobile, dob) {
  return Request.get(`/add_member/?mobile=${mobile}&dob=${dob}`);
}

// MASTER
export function getCountries() {
  return Request.get("/countries/");
}

export function getStates(countryId) {
  return Request.get(`/states/${countryId}/`);
}

export function getCities(stateId) {
  return Request.get(`/cities/${stateId}/`);
}

export function getVillages() {
  return Request.get("/villages/");
}

export function getGotra() {
  return Request.get("/gotra/");
}

export function getEducations() {
  return Request.get("/educations/");
}

// FEEDBACK
export function postFeedBack(data) {
  return Request.post("/feedback/", data);
}

// COMMUNITY HELP
export function postHelp(id, data) {
  return Request.post(`/help/${id}/`, data);
}

// BANNER IMAGE
export function getLatestAdBanner() {
  return Request.get("/banner/latest/");
}

// CONTACT US
export function getCommitteeMaster() {
  return Request.get("/committees/1/");
}

export function getCommitteeMembers() {
  return Request.get("/committee-members/1/");
}

// SAMAJ COMMITTEES
export function getCommitteeMasterList() {
  return Request.get("/committees/");
}

export function getCommitteeMembersList(committeeId) {
  return Request.get(`/committee-members/${committeeId}/`);
}

// SAMAJ DARSHAN
export function getTemples(templeName) {
  if (templeName?.length > 0) {
    return Request.get(`/temples/?name=${templeName}`);
  }

  return Request.get("/temples/");
}

export function getTemplesDetail(templeId) {
  return Request.get(`/temples/${templeId}/`);
}

// NOTIFICATIONS
export function getBirthdayNotifications() {
  return Request.get("/notifications/");
}

// MEMBER ACHIEVEMENTS
export function getMembersAchievements() {
  return Request.get("/achievements/");
}

export function getMembersAchievementsById(achievementId) {
  return Request.get(`/achievements/${achievementId}/`);
}
