import { createAsyncThunk } from "@reduxjs/toolkit";

import { postHelp } from "../../axios/axios";
import Notifications from "../../helpers/Notifications";

export const postHelpAction = createAsyncThunk(
  "communityHelp/post",
  async (props, thunkAPI) => {
    try {
      const response = await postHelp(props.id, props.data);
      Notifications.success("Successfully submitted.");

      return response.data;
    } catch (error) {
      return Notifications.error("Something went wrong.");
    }
  }
);
