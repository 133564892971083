import { createSlice } from "@reduxjs/toolkit";

export const browserSlice = createSlice({
  name: "browser",
  initialState: {
    bucketList: [],
    loadingBucketList: false,
    selectedBucket: null,
    files: [],
    loadingFiles: false,
    prefixes: [],
    accountId: null,
    error: null,
  },
  reducers: {
    bucketListLoading: (state, action) => {
      state.loadingBucketList = true;
      state.prefixes = [];
      state.accountId = action.payload.accountId;
      // eslint-disable-next-line no-unused-expressions
      (state.error = null)((state.bucketList = []));
    },
    getBucketListSuccess: (state, action) => {
      state.loadingBucketList = false;
      state.prefixes = [];
      // eslint-disable-next-line no-unused-expressions
      (state.error = null)((state.bucketList = action.payload.buckets));
    },
    getBucketListFailure: (state, action) => {
      state.loadingBucketList = false;
      state.prefixes = [];
      // eslint-disable-next-line no-unused-expressions
      (state.error = action.payload.message)((state.bucketList = []));
    },
    setSelectedBucket: (state, action) => {
      state.selectedBucket = action.payload.bucket;
    },
  },
});

const browserActions = browserSlice.actions;

export { browserActions };

export default browserSlice.reducer;
