import { createAsyncThunk } from "@reduxjs/toolkit";
import Request from "../../helpers/request.service";


export const choghadiyaAction = createAsyncThunk(
    "get/Choghadiya",
    async (props, { rejectWithValue }) => {
        try {
            const response = await Request.get(`/choghadiya/?days=${props.day}`);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
