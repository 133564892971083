import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getMembersAchievements,
  getMembersAchievementsById,
} from "../../axios/axios";
import Notifications from "../../helpers/Notifications";

export const getMemberAchievementsList = createAsyncThunk(
  "memberAchievements/getList",
  async (memberId, thunkAPI) => {
    try {
      const response = await getMembersAchievements();

      return response.data;
    } catch (error) {
      if (error.response.status === 404) {
        Notifications.info("No data.");
      } else {
        Notifications.error("Something went wrong.");
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getMembersAchievementsDetail = createAsyncThunk(
  "memberAchievementsDetail/getList",
  async (achievementId, thunkAPI) => {
    try {
      const response = await getMembersAchievementsById(achievementId);

      return response.data;
    } catch (error) {
      if (error.response.status === 404) {
        Notifications.info("No data.");
      } else {
        Notifications.error("Something went wrong.");
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);
