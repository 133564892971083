import { createAsyncThunk } from "@reduxjs/toolkit";
import Request from "../../helpers/request.service";

import {
  checkMember,
  createMember,
  getPersonalProfile,
  updatePersonalProfile,
} from "../../axios/axios";
import Notifications from "../../helpers/Notifications";
import { cityDropDown, stateDropDown } from "../../store/master/masterAction";

export const memberListAction = createAsyncThunk(
  "member/getList",
  async (props, { rejectWithValue }) => {
    try {
      const response = await Request.get(props.query);
      return { response, reset: props.reset };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const personalProfile = createAsyncThunk(
  "member/get",
  async (memberId, thunkAPI) => {
    try {
      const response = await getPersonalProfile(memberId);
      const member = response.data.data;

      /* get member's state details */
      thunkAPI.dispatch(stateDropDown(member?.country));
      /* get member's city details */
      thunkAPI.dispatch(cityDropDown(member?.state));

      return member;
    } catch (error) {
      if (error.response.status === 404) {
        Notifications.info("No data.");
      } else {
        Notifications.error("Something went wrong.");
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updatedPersonalProfile = createAsyncThunk(
  "member/update",
  async (props, thunkAPI) => {
    try {
      const response = await updatePersonalProfile(props.id, props.data);
      Notifications.success("Successfully updated.");

      return response.data.data;
    } catch (error) {
      return Notifications.error("Something went wrong.");
    }
  }
);

export const createNewMember = createAsyncThunk(
  "member/post",
  async (props, thunkAPI) => {
    try {
      const toSend = { ...props };
      for (let i = 0; i < toSend?.member_detail?.length; i++) {
        delete toSend?.member_detail[i]?.errors;
      }
      const response = await createMember(toSend);
      Notifications.success("Successfully added.");

      return response.data.data;
    } catch (error) {
      return Notifications.error("Something went wrong.");
    }
  }
);

export const checkMemberExists = createAsyncThunk(
  "member/check",
  async (props, { rejectWithValue }) => {
    try {
      const response = await checkMember(props.mobile, props.dob);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
