import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTemples, getTemplesDetail } from "../../axios/axios";

import Notifications from "../../helpers/Notifications";

export const listTemples = createAsyncThunk(
  "temples-list/get",
  async (props, thunkAPI) => {
    try {
      const response = await getTemples(props);

      return response.data;
    } catch (error) {
      if (error.response.status === 404) {
        Notifications.info("No data.");
      } else {
        Notifications.error("Something went wrong.");
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const detailTemple = createAsyncThunk(
  "temple-detail/get",
  async (props, thunkAPI) => {
    try {
      const response = await getTemplesDetail(props);

      return response.data;
    } catch (error) {
      if (error.response.status === 404) {
        Notifications.info("No data.");
      } else {
        Notifications.error("Something went wrong.");
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);
