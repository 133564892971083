import { createSlice } from '@reduxjs/toolkit';

import Auth from '../../helpers/auth.service';
import { sendOTP, loginUser } from './authThunk';


export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: Auth.user,
    loading: false,
    token: Auth.token,
    success: null,
    error: null,
    showOtp: false,
  },
  reducers: {
    loginUserLoading: (state) => {
      state.loading = true;
      state.user = {};
      state.error = state.token = null;
    },
    loginUserSuccess: (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
      state.token = Auth.token(action.payload.token);
      state.error = null;
      state.showOtp = true;
    },
    loginUserFailure: (state, action) => {
      state.loading = false;
      state.user = {};
      state.token = null;
      state.error = action.payload.message;
    },
    logOutUser: (state) => {
      state.loading = false;
      state.user = {};
      state.token = null;
      state.error = null;
      state.showOtp = false;
      state.success = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendOTP.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.showOtp = true;
        state.success = action.payload.data.status;
      })
      .addCase(sendOTP.pending, (state, action) => {
        state.loading = true;
        state.success = null;
        state.showOtp = false;
        state.error = null;
      })
      .addCase(sendOTP.rejected, (state, action) => {
        state.loading = false;
        state.success = null;
        state.showOtp = false;
        state.error = action.payload.response.data.status;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.showOtp = false;
        state.user = { name: action.payload.data.data.full_name, member_id: action.payload.data.data.member_id };
        state.token = action.payload.data.data.token.access;
        state.success = action.payload.data.status;
      })
      .addCase(loginUser.pending, (state, action) => {
        state.loading = true;
        state.success = null;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.success = null;
        state.error = action.payload.response.data.status;
      })
  }
});

// Action creators are generated for each case reducer function
const authActions = authSlice.actions;

export { authActions };

export default authSlice.reducer;
